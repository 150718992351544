const colors = {
  lightning_yellow: '#ffd745',
  yellow: '#ffd745',
  brand_yellow: '#fbc91b',
  orange: '#e08700',
  light_yellow: '#FFFAE8',
  black: 'rgba(0, 0, 0, 0.8)',
  black_33: '#333333',
  alt_black: '#2c2c2c',
  white: '#ffffff',
  white_f2: '#f2f2f2',
  gray_aa: '#aaaaaa',
  gray_66: '#666666',
  lighter_white: 'rgb(165, 165, 165)',
  light_white_fa: '#FAFAFA',
  light_white_e2: '#E2E2E2',
  radical_red: '#ff2750',
  lavender_blush: '#ffebef',
  emerald: '#2DCB48',
  purple_fcp: '#4B3680',
  dark_green: '#285230',
  light_green: '#00a676',
  dark_body: '#222324',
  gray_mid: '#7d7d7d',
  light_gray_body: '#f6f6f6',
  dark_gray_body: '#292929',
  lighter_gray_body: '#474747',
  white_border: '#e5e5e5',
  gray_border: '#bfbfbf',
  lightest_gray: '#ccc',
  light_gray_44: '#444444',
  facebook_blue: '#4267B2',
};

export const grays = {
  wild_sand: '#f6f6f6',
  alto: 'rgba(224, 224, 224, 0.8)',
  gray: '#888888',
  emperor: '#4F4F4F',
  mine_shaft: '#2c2c2c',
  half_black: 'rgba(0, 0, 0, 0.54)',
};

export const newColors = {
  primary: { s200: '#E08700', s100: '#FFD745', s50: '#FFEBA2', s10: '#FFFBEC' },
  neutral: {
    s70: '#808080',
    s60: '#A5A5A5',
    s10: '#FAFAFA',
  },
  radical_red: '#ff2750',
};

export default colors;
