/**
 * Asynchronously loads the component
 */

import { lazyLoad } from 'utils/loadable';

export const DashboardPage = lazyLoad(
  () => import(/* webpackChunkName: "DashboardPage" */ './index'),
  module => module.DashboardPage,
);
