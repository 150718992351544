import axios from 'axios';
import AuthStorage from '../modules/authStorage';
import { getTokensService } from '../services/authService';

const protectedAxiosRequest = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_ENDPOINT,
});

const protectedReqConfigMiddleware = config => {
  const accessToken = 'Bearer '.concat(AuthStorage.getUserToken());

  if (accessToken) {
    const argHeaders = config?.headers || {};
    config.headers = {
      Authorization: accessToken,
      ...argHeaders,
    };
  }

  return config;
};

const protectedReqErrorMiddleware = error => {
  return Promise.reject(error);
};

const protectedResMiddleware = response => response;
const protectedResErrorMiddleware = error => {
  const status = error?.response?.status;
  const originalRequest = error.config;
  const refresh = AuthStorage.getRefreshToken();

  if (refresh && status === 401) {
    const payload = { refresh };
    return getTokensService(payload)
      .then(response => {
        originalRequest.headers.Authorization = `Bearer ${response.data.access}`;
        return protectedAxiosRequest(originalRequest);
      })
      .catch(error => {
        return Promise.reject(error);
      });
  }

  return Promise.reject(error);
};

// Request interceptors
protectedAxiosRequest.interceptors.request.use(
  protectedReqConfigMiddleware,
  protectedReqErrorMiddleware,
);

// Response interceptors
protectedAxiosRequest.interceptors.response.use(
  protectedResMiddleware,
  protectedResErrorMiddleware,
);

export default protectedAxiosRequest;
