/**
 * Asynchronously loads the component
 */

import { lazyLoad } from 'utils/loadable';

export const HomePagev2 = lazyLoad(
  () => import(/* webpackChunkName: "HomePage" */ './index'),
  module => module.HomePage,
);
