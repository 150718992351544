import { createMuiTheme } from '@material-ui/core/styles';

import colors, { grays, newColors } from '../app/constants/colors';
import fonts from '../app/constants/fonts';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: newColors.primary.s100,
    },
    secondary: {
      main: grays.mine_shaft,
    },
    error: {
      main: colors.radical_red,
      light: colors.lavender_blush,
    },
  },
  typography: {
    fontFamily: fonts.helvetica,
  },
});
theme.overrides = {
  MuiFormControl: {
    root: {
      margin: theme.spacing(1),
      marginLeft: 0,
      marginRight: 0,
      minWidth: 120,
    },
    marginNormal: {
      marginTop: '0px 0px',
    },
  },
  MuiTextField: {
    root: {
      // height: 48,
    },
  },
  MuiList: {
    root: {
      background: 'initial',
      border: 'none',
      color: colors.black,
    },
  },
  MuiInputBase: {
    root: {
      borderRadius: 4,
      background: colors.white,
    },
    input: {
      lineHeight: '20px',
      padding: '14px 16px',
      background: colors.white,
    },
    adornedEnd: {
      padding: '5px 10px !important',
    },
  },
  MuiOutlinedInput: {
    root: {
      borderRadius: 4,
      legend: {
        display: 'none',
      },
      border: '1px solid rgba(0, 0, 0, 0.23)',
    },
    input: {
      lineHeight: '20px',
      padding: '14px 16px',
      borderRadius: 4,
    },
    notchedOutline: {
      display: 'none !important',
    },
    inputMultiline: {
      padding: '18.5px 14px !important',
    },
    multiline: {
      padding: '0 !important',
    },
  },
  MuiInputLabel: {
    outlined: {
      transform: 'translate(0,-20px) scale(0.75) !important',
    },
  },
  MuiButton: {
    root: {
      fontFamily: fonts.poppins,
      borderRadius: 4,
      fontSize: 16,
      lineHeight: '24px',
      padding: '16px 24px',
      fontWeight: 500,
      textTransform: 'none',
      boxShadow: 'none',
    },
    sizeSmall: {
      fontSize: 12,
      height: 31,
      borderRadius: 4,
    },
    sizeLarge: {
      fontSize: 18,
      height: 83,
      padding: '28px 40px',
      lineHeight: '27px',
      borderRadius: 4,
      boxShadow: '6px 10px 30px rgba(169, 102, 0, 0.16)',
    },
    text: {
      padding: '14px 16px',
      color: 'rgba(0, 0, 0, 1)',
    },
    outlined: {
      padding: '14px 16px',
    },
    containedPrimary: {
      color: grays.mine_shaft,
      boxShadow: 'none',
      backgroundColor: colors.lightning_yellow,
    },
    containedSecondary: {
      color: colors.white,
      backgroundColor: colors.alt_black,
      '&:hover': {
        opacity: 0.8,
      },
    },
    outlinedSecondary: {
      color: '#2c2c2c',
      border: '1px solid rgba(44, 44, 44, 0.5)',
      background: 'transparent',
    },
  },
  MuiIconButton: {
    root: {
      color: 'rgba(0, 0, 0, 1)',
    },
    colorSecondary: {
      color: colors.alt_black,
    },
  },
  MuiSwitch: {
    track: {
      backgroundColor: colors.gray_mid,
      '$checked$checked + &': {
        backgroundColor: '#ffd745',
      },
    },
    switchBase: {
      // Controls default (unchecked) color for the thumb
      color: colors.gray_mid,
      '&$checked': {
        // Controls checked color for the thumb
        color: '#ffd745',
      },
    },
  },
  MuiTable: {
    root: {
      fontFamily: fonts.helveticaNeue,
    },
  },
  MuiTableCell: {
    root: {
      padding: theme.spacing(0.5),
    },
    head: {
      padding: theme.spacing(2),
      paddingLeft: theme.spacing(0.5),
      paddingTop: 0,
      paddingBottom: 0,
      fontWeight: 'bold',
      color: colors.black,
    },
    body: {
      color: `${colors.alt_black} !important`,
      background: `${colors.white}`,
    },
  },
  MuiTypography: {
    h1: {
      fontFamily: fonts.montserrat,
    },
    h2: {
      fontFamily: fonts.montserrat,
    },
    body1: {
      fontFamily: fonts.helveticaNeue,
      color: colors.black,
    },
  },
  MuiDialog: {
    paper: {
      borderRadius: 12,
      padding: theme.spacing(2),
      background: colors.white,
      border: 'initial',
    },
    paperWidthSm: { maxWidth: 'unset' },
  },
  MuiDialogActions: {
    root: {
      padding: 0,
    },
  },
  MuiDialogTitle: {
    root: {
      color: colors.black,
    },
  },
  MuiFormLabel: {
    root: {
      color: 'rgba(0, 0, 0, 0.54)',
    },
  },
  MuiCheckbox: {
    root: {
      color: 'rgba(0, 0, 0, 0.54)',
    },
    colorSecondary: {
      '&.Mui-checked': {
        color: colors.black,
      },
    },
  },
};

export default theme;
