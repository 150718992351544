/**
 * Asynchronously loads the component
 */

import { lazyLoad } from 'utils/loadable';

export const TranslatePage = lazyLoad(
  () => import(/* webpackChunkName: "TranslatePage" */ './index'),
  module => module.TranslatePage,
);
