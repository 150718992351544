/**
 *
 * App
 *
 * This component is the skeleton around the actual pages, and should only
 * contain code that should be seen on all pages. (e.g. navigation bar)
 */

import React, { useEffect, useRef } from 'react';
import { Helmet } from 'react-helmet-async';
import { BrowserRouter } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useInjectReducer, useInjectSaga } from 'redux-injectors';
import * as Sentry from '@sentry/react';
import { SnackbarProvider } from 'notistack';

import { ThemeProvider } from '@material-ui/core/styles';

import * as userInfoSlice from '../store/userInfo/slice';
import { getUserInfoSaga } from '../store/userInfo/saga';
import { selectUserInfo } from '../store/userInfo/selectors';

import theme from 'styles/theme';
import { GlobalStyle } from 'styles/global-styles';
import '../styles/all.scss';

import { Routes } from './routes';

export function App() {
  useInjectReducer({
    key: userInfoSlice.sliceKey,
    reducer: userInfoSlice.reducer,
  });
  useInjectSaga({ key: userInfoSlice.sliceKey, saga: getUserInfoSaga });

  const userInfo = useSelector(selectUserInfo);

  const notistackRef = useRef<any>(null);
  const dispatch = useDispatch();

  useEffect(() => {
    if (process.env.NODE_ENV === 'production' && userInfo.email) {
      Sentry.setUser({ email: userInfo.email });
      (window as any).ga4?.gtag('set', 'user_properties', {
        user_id: userInfo.id,
      });
      (window as any).ga4?.gtag('config', 'GA_MEASUREMENT_ID', {
        user_id: userInfo.id,
      });
    }
  }, [userInfo]);

  const useEffectOnMount = (effect: React.EffectCallback) => {
    useEffect(effect, []); // eslint-disable-line react-hooks/exhaustive-deps
  };
  useEffectOnMount(() => {
    dispatch(userInfoSlice.actions.getUserInfo());
  });

  return (
    <ThemeProvider theme={theme}>
      <SnackbarProvider
        maxSnack={3}
        ref={notistackRef}
        action={key => (
          <span
            style={{ cursor: 'pointer', marginRight: 8 }}
            onClick={() =>
              notistackRef.current && notistackRef.current.closeSnackbar(key)
            }
          >
            X
          </span>
        )}
      >
        <BrowserRouter>
          <Helmet titleTemplate="%s - Lesan" defaultTitle="Lesan">
            <meta
              name="description"
              content="A platform for creators to localize their content in a timely and cost effective way"
            />
          </Helmet>
          <Routes />
          <GlobalStyle />
        </BrowserRouter>
      </SnackbarProvider>
    </ThemeProvider>
  );
}
