import TagManager from 'react-gtm-module';

export const initiateGTM = () => {
  const tagManagerArgs = {
    gtmId: process.env.REACT_APP_GTM_ID || '',
  };

  TagManager.initialize(tagManagerArgs);
  //To track if tag manager is already loaded to prevent multiple loads
  // @ts-ignore
  window.gtmLoaded = true;
};

export const trackEvent = (action, label, path, data = {}) => {
  // @ts-ignore
  window.dataLayer?.push({
    event: action,
    page: {
      title: label,
      url: path,
      data: data,
    },
  });
};

export const initGA = () => {
  const path = window.location.pathname + window.location.search;
  trackEvent('pageview', 'Page View', path);
};
