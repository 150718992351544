export const scrollToTop = () => {
  window.scrollTo({
    top: 0,
    behavior: 'smooth',
  });
};

export const scrollToRef = (ref, negOffset = 0) =>
  window.scrollTo(0, ref?.current?.offsetTop - negOffset);

export const scrollToDom = (element, offset) =>
  window.scrollTo(0, element?.offsetTop - offset);

export const isInViewport = ref => {
  const rect = ref.current.getBoundingClientRect();
  return (
    rect.top >= 0 &&
    rect.left >= 0 &&
    rect.bottom <=
      (window.innerHeight || document.documentElement.clientHeight) &&
    rect.right <= (window.innerWidth || document.documentElement.clientWidth)
  );
};

export const isAboveViewportHeight = (ref, extraSpace) => {
  const rect = ref.current.getBoundingClientRect();

  return (
    rect.bottom + extraSpace >=
    (window.innerHeight || document.documentElement.clientHeight)
  );
};

export const isInViewportHeight = ref => {
  const rect = ref.current.getBoundingClientRect();
  return (
    rect.top >= 0 &&
    rect.bottom <= (window.innerHeight || document.documentElement.clientHeight)
  );
};

export const minDesktopWidth = 1200;
export const fbVideoDesktopHeight = 490;
export const fbVideoMobileHeight = 914;

export const checkIsDesktop = () => window.innerWidth >= minDesktopWidth;

export const mobileNavHeight = 64;
export const desktopNavHeight = 80;
