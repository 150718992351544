import Cookies from 'js-cookie';

const ACCESS_TOKEN_KEY_COOKIE = 'accessToken';
const REFRESH_TOKEN_KEY_COOKIE = 'refreshToken';

class AuthStorage {
  static setTokens(accessToken, refreshToken) {
    localStorage.setItem('accessToken', accessToken);
    localStorage.setItem('refreshToken', refreshToken);
  }

  static setUserToken(accessToken) {
    localStorage.setItem('accessToken', accessToken);
  }

  /**
   * De-authenticate a user.
   * Remove a token from session Storage.
   *
   */
  static deAuthenticateUser() {
    localStorage.removeItem('userData');
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
    Cookies.remove(ACCESS_TOKEN_KEY_COOKIE, {
      path: '/',
      domain: 'lesan.io',
    });
    Cookies.remove(REFRESH_TOKEN_KEY_COOKIE, {
      path: '/',
      domain: 'lesan.io',
    });
  }

  /**
   * return user token
   *
   * @returns {string}
   */
  static getUserToken() {
    return localStorage.getItem('accessToken');
  }

  /**
   * return user token
   *
   * @returns {string}
   */
  static getRefreshToken() {
    return localStorage.getItem('refreshToken');
  }

  /**
   * return user token
   *
   * @returns {string}
   */
  static getUserTokenFromCookies() {
    return Cookies.get(ACCESS_TOKEN_KEY_COOKIE);
  }

  /**
   * return user token
   *
   * @returns {string}
   */
  static getRefreshTokenFromCookies() {
    return Cookies.get(REFRESH_TOKEN_KEY_COOKIE);
  }
}

export default AuthStorage;
