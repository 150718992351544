import React, { createContext, useContext, useState, useEffect } from 'react';
import colors, { grays } from '../../constants/colors';

const DARK_MODE_SESSION_KEY_NAME = 'darkMode';

const ThemeContext = createContext({
  isDarkMode: false,
  setIsDarkMode: (value: boolean) => {},
  themes: {
    light: {
      background: '',
      headingColor: '',
      titleColor: '',
      timelineBackground: '',
      timelineBorder: '',
      timelineOuterBorder: '',
      canvasBackground: '',
    },
    dark: {
      background: '',
      headingColor: '',
      titleColor: '',
      timelineBackground: '',
      timelineBorder: '',
      timelineOuterBorder: '',
      canvasBackground: '',
    },
  },
});

export const ThemeContextProvider = ({ children }) => {
  const [isDarkMode, setDarkMode] = useState<boolean>(false);

  const themes = {
    light: {
      background: colors.white,
      headingColor: grays.half_black,
      titleColor: colors.black,
      timelineBackground: colors.light_gray_body,
      timelineBorder: colors.white_border,
      timelineOuterBorder: colors.gray_border,
      canvasBackground: 'transparent',
    },
    dark: {
      background: colors.dark_body,
      headingColor: colors.lighter_white,
      titleColor: colors.lighter_white,
      timelineBackground: colors.dark_body,
      timelineBorder: colors.lighter_gray_body,
      timelineOuterBorder: colors.lighter_gray_body,
      canvasBackground: colors.lightest_gray,
    },
  };

  const setIsDarkMode = (value: boolean) => {
    setDarkMode(value);
    window.localStorage.setItem(DARK_MODE_SESSION_KEY_NAME, value.toString());
  };

  const value: {
    themes: {
      light: {
        background: string;
        headingColor: string;
        titleColor: string;
        timelineBackground: string;
        timelineBorder: string;
        timelineOuterBorder: string;
        canvasBackground: string;
      };
      dark: {
        background: string;
        headingColor: string;
        titleColor: string;
        timelineBackground: string;
        timelineBorder: string;
        timelineOuterBorder: string;
        canvasBackground: string;
      };
    };
    isDarkMode: boolean;
    setIsDarkMode: any;
  } = {
    isDarkMode: isDarkMode,
    setIsDarkMode,
    themes,
  };

  // Get dark mode state from session storage
  useEffect(() => {
    const darkModeInSession = window.localStorage.getItem(
      DARK_MODE_SESSION_KEY_NAME,
    );
    if (darkModeInSession) {
      setDarkMode(darkModeInSession === 'true');
    }
  }, []);

  return (
    <ThemeContext.Provider value={value}>{children}</ThemeContext.Provider>
  );
};

export const useThemeContext = () => useContext(ThemeContext);
