import React from 'react';
import { Switch, Route, useHistory } from 'react-router-dom';

import { HomePagev2 } from './containers/HomePagev2/Loadable';
import { DashboardPage } from './containers/DashboardPage/Loadable';
import { TranscribePage } from './containers/TranscribePage/Loadable';
import { TranslatePage } from './containers/TranslatePage/Loadable';
import { LoginPage } from './containers/LoginPage/Loadable';
import { MemoizedOnboardingPage } from './containers/OnboardingPage/Loadable';
import { SignupSuccessPage } from './containers/SignupSuccessPage/Loadable';
import { ContactUsPage } from './containers/ContactUsPage/Loadable';
import { PrivacyPolicyPage } from './containers/staticPages/PrivacyPolicyPage/Loadable';
import { BlogMainPage } from './containers/BlogMainPage/Loadable';
import { BlogPostViewPage } from './containers/BlogPostViewPage/Loadable';
import { BlogPostEditPage } from './containers/BlogPostEditPage/Loadable';
import { NotFoundPage } from './containers/NotFoundPage/Loadable';
import { AnalyticPage } from './containers/AnalyticPage/Loadable';
import { VideoAnalyticPage } from './containers/VideoAnalyticPage/Loadable';
import { CreatorAdminPage } from './containers/AdminPage/creator/Loadable';
import { TranslatorAdminPage } from './containers/AdminPage/translator/Loadable';
import { CreatorSignupAdmin } from './containers/AdminPage/creatorSignup/Loadable';

export function Routes() {
  const history = useHistory();
  history.listen(location => {
    (window as any).ga4?.pageview(location.pathname);
  });

  return (
    <Switch>
      <Route exact path="/" component={HomePagev2} />
      <Route exact path="/dashboard" component={DashboardPage} />
      <Route exact path="/dashboard/:language_code" component={DashboardPage} />

      <Route
        exact
        path="/transcribe/:video_id/:language_code"
        component={TranscribePage}
      />
      <Route exact path="/translate/:task_id" component={TranslatePage} />
      <Route exact path="/proofread/:task_id" component={TranslatePage} />

      <Route exact path="/login" component={LoginPage} />
      <Route exact path="/preview" component={MemoizedOnboardingPage} />
      <Route exact path="/success" component={SignupSuccessPage} />

      <Route exact path="/contact" component={ContactUsPage} />
      <Route exact path="/privacy-policy" component={PrivacyPolicyPage} />

      <Route exact path="/blog" component={BlogMainPage} />
      <Route exact path="/blog/new" component={BlogPostEditPage} />
      <Route exact path="/blog/:url_slug/edit" component={BlogPostEditPage} />
      <Route exact path="/blog/:url_slug" component={BlogPostViewPage} />
      <Route exact path="/stats/:creator" component={AnalyticPage} />
      <Route exact path="/videos/:creator" component={VideoAnalyticPage} />
      <Route exact path="/admin/creators" component={CreatorAdminPage} />
      <Route exact path="/admin/translators" component={TranslatorAdminPage} />
      <Route
        exact
        path="/admin/creator-signup"
        component={CreatorSignupAdmin}
      />

      <Route component={NotFoundPage} />
    </Switch>
  );
}
