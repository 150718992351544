import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';

import { checkIsDesktop } from '../../../utils/domHelpers';

export const WindowWidthContext = createContext();

export const WindowWidthContextProvider = ({ children }) => {
  const [isDesktopView, setIsDesktopView] = useState(false);

  const updateModeOnWindowChange = () => {
    setIsDesktopView(checkIsDesktop());
  };

  const initListener = useCallback(() => {
    window.addEventListener('resize', () => updateModeOnWindowChange());
  }, []);

  const removeListener = useCallback(() => {
    window.removeEventListener('resize', updateModeOnWindowChange);
  }, []);

  useEffect(() => {
    initListener();

    return removeListener();
  }, [initListener, removeListener]);

  useEffect(() => {
    setIsDesktopView(checkIsDesktop());
  }, []);

  const value = { isDesktopView };

  return (
    <WindowWidthContext.Provider value={value}>
      {children}
    </WindowWidthContext.Provider>
  );
};

export const useWindowWidthContext = () => useContext(WindowWidthContext);
