/**
 * index.js
 *
 * This is the entry file for the application, only setup and boilerplate
 * code.
 */

import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import * as serviceWorker from 'serviceWorker';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import 'sanitize.css/sanitize.css';

// Import root app
import { App } from 'app';

import { HelmetProvider } from 'react-helmet-async';

import { configureAppStore } from 'store/configureStore';
import { ThemeContextProvider } from './app/contexts/ThemeContext';
import { datadogRum } from '@datadog/browser-rum-recorder';
import { initiateGTM } from './utils/analytics';
import { WindowWidthContextProvider } from './app/contexts/WindowWidthContext';

// Initialize languages
// import './locales/i18n';

const isProd = process.env.REACT_APP_ENV === 'production';

if (isProd) {
  initiateGTM();
}

// hotjar.initialize(2114894, 6);
datadogRum.init({
  applicationId: process.env.REACT_APP_DATADOG_APPLICATION_ID || '',
  clientToken: process.env.REACT_APP_DATADOG_CLIENT_TOKEN || '',
  site: 'datadoghq.com',
  service: isProd ? 'lesan' : 'lesan-dev',
  // Specify a version number to identify the deployed version of your application in Datadog
  // version: '1.0.0',
  sampleRate: 100,
  trackInteractions: true,
});

datadogRum.startSessionReplayRecording();

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  environment: process.env.REACT_APP_ENV,
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: 0.5,
});

const store = configureAppStore();
const MOUNT_NODE = document.getElementById('root') as HTMLElement;

interface Props {
  Component: typeof App;
}
const ConnectedApp = ({ Component }: Props) => (
  <ThemeContextProvider>
    <WindowWidthContextProvider>
      <Provider store={store}>
        <HelmetProvider>
          <React.StrictMode>
            <Component />
          </React.StrictMode>
        </HelmetProvider>
      </Provider>
    </WindowWidthContextProvider>
  </ThemeContextProvider>
);
const render = (Component: typeof App) => {
  ReactDOM.render(<ConnectedApp Component={Component} />, MOUNT_NODE);
};

if (module.hot) {
  // Hot reloadable translation json files and app
  // modules.hot.accept does not accept dynamic dependencies,
  // have to be constants at compile-time
  module.hot.accept(
    [
      './app',
      // './locales/i18n',
    ],
    () => {
      ReactDOM.unmountComponentAtNode(MOUNT_NODE);
      const App = require('./app').App;
      render(App);
    },
  );
}

render(App);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
