import axios from 'axios';
import AuthStorage from '../modules/authStorage';

export const getTokensService = payload => {
  return axios
    .post(
      `${process.env.REACT_APP_BACKEND_ENDPOINT}/api/users/token/refresh/`,
      payload,
    )
    .then(response => {
      const { access, refresh } = response.data;
      AuthStorage.setTokens(access, refresh);
      return response;
    });
};
