/**
 * Asynchronously loads the component
 */

import { lazyLoad } from 'utils/loadable';

export const TranscribePage = lazyLoad(
  () => import(/* webpackChunkName: "TranscribePage" */ './index'),
  module => module.TranscribePage,
);
