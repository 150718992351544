/**
 * Asynchronously loads the component
 */

import { lazyLoad } from 'utils/loadable';

export const NotFoundPage = lazyLoad(
  () => import(/* webpackChunkName: "NotFoundPage" */ './index'),
  module => module.NotFoundPage,
);
