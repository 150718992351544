import { createSlice } from 'utils/@reduxjs/toolkit';

import { State } from './types';

const emptyUser = {
  id: 0,
  email: '',
  username: '',
  customer_id: null,
  translator_id: null,
  permissions: {},
  first_name: null,
  is_staff: false,
};

export const initialState: State = {
  data: emptyUser,
  loading: true,
  error: false,
};

const userInfoSlice = createSlice({
  name: 'userInfo',
  initialState,
  reducers: {
    getUserInfo(state) {
      state.loading = true;
      state.error = false;
    },
    clearUserInfo(state) {
      state.data = emptyUser;
      state.loading = false;
      state.error = false;
    },
    userInfoLoaded(state, action) {
      const userInfoData = action.payload;
      if (userInfoData !== false) {
        state.data = userInfoData;

        state.error = false;
      }
      state.loading = false;
    },
    userInfoError(state, action) {
      state.loading = false;
      state.error = true;
    },
  },
});

export const { actions, reducer, name: sliceKey } = userInfoSlice;
