import { createSelector } from '@reduxjs/toolkit';

import { initialState } from './slice';
import { State } from './types';

// First select the relevant part from the state
const selectDomain = (state: { userInfo: State }) =>
  state.userInfo || initialState;

export const selectUserInfo = createSelector([selectDomain], state => ({
  ...state.data,
  loading: state.loading,
}));

export const selectLoading = createSelector(
  [selectDomain],
  state => state.loading,
);

export const selectError = createSelector([selectDomain], state => state.error);
