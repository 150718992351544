/**
 * Asynchronously loads the component
 */

import { lazyLoad } from 'utils/loadable';

export const TranslatorAdminPage = lazyLoad(
  () => import(/* webpackChunkName: "HomePage" */ './index'),
  module => module.TranslatorAdminPage,
);
