/**
 * Asynchronously loads the component
 */

import { lazyLoad } from 'utils/loadable';

export const PrivacyPolicyPage = lazyLoad(
  () => import(/* webpackChunkName: "PrivacyPolicyPage" */ './index'),
  module => module.PrivacyPolicyPage,
);
