const fonts = {
  montserrat: ['"Montserrat"', 'Arial', 'sans-serif'].join(', '),
  helvetica: ['Helvetica', 'Arial', 'sans-serif'].join(', '),
  helveticaNeue: ['"Helvetica Neue"', 'Helvetica', 'Arial', 'sans-serif'].join(
    ', ',
  ),
  poppins: ['Poppins', 'Open-Sans', 'sans-serif'].join(', '),
};

export default fonts;
