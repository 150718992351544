import { put, takeLatest } from 'redux-saga/effects';

import { actions } from './slice';
import { UserInfo } from '../../types/UserInfo';
import protectedAxiosRequest from '../../app/helpers/protectedAxiosRequest';

export function* getUserInfo() {
  const requestURL = `/api/accounts/info/`;

  try {
    const userInfo: {
      data: UserInfo;
    } = yield protectedAxiosRequest.get(requestURL);
    if (userInfo) {
      yield put(actions.userInfoLoaded(userInfo.data));
    } else {
      yield put(actions.userInfoError(''));
    }
  } catch (err) {
    if (err.response?.status === 404) {
      yield put(actions.userInfoError(''));
    } else {
      yield put(actions.userInfoError(''));
    }
  }
}

/**
 * Root saga manages watcher lifecycle
 */
export function* getUserInfoSaga() {
  yield takeLatest(actions.getUserInfo.type, getUserInfo);
}
