import { createGlobalStyle } from 'styled-components';

import fonts from '../app/constants/fonts';

export const GlobalStyle = createGlobalStyle`
  html {
    height: 100%;
    width: 100%;
    font-family: ${fonts.poppins};

    transition: 0.2s;
    /* background: #E5E5E5; */
  }

  body {
    height: 100%;
    width: 100%;
    overflow: hidden;
    overflow-y: auto;
    margin: 0;
  }

  #root {
    height: 100%;
    width: 100%;
    min-height: 100%;
    min-width: 100%;
    position: relative;
  }

  .userback-button {
    display: none !important;
  }

  .h1 {
    font-weight: bold;
    font-size: 60px;
    line-height: 120%;
    margin: 0;
  }

  .h2 {
    font-weight: bold;
    font-size: 40px;
    line-height: 136%;
    margin: 0;
  }

  .h3 {
    font-weight: bold;
    font-size: 30px;
    line-height: 136%;
    margin: 0;
  }

  .caption-24 {
    font-weight: normal;
    font-size: 24px;
    line-height: 36px;
    letter-spacing: 0.06em;
    margin: 0;
  }

  .caption-18 {
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    letter-spacing: 0.16em;
    margin: 0;
  }

  .caption-16 {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.16em;
    margin: 0;
  }

  .text-20-semibold {
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
    margin: 0;
  }

  .text-20 {
    font-size: 20px;
    line-height: 30px;
    margin: 0;
  }

  .text-18 {
    font-weight: normal;
    font-size: 18px;
    line-height: 27px;
    margin: 0;
  }

  .text-16-bold {
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    margin: 0;
  }

  .text-16 {
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    margin: 0;
  }

  .text-14 {
    font-weight: normal;
    font-size: 14px;
    line-height: 21px;
    margin: 0;
  }

  .text-12-bold {
    font-weight: bold;
    font-size: 12px;
    line-height: 18px;
    margin: 0;
  }

  .text-12-semibold {
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
    margin: 0;
  }

  .text-12 {
    font-weight: normal;
    font-size: 12px;
    line-height: 18px;
    margin: 0;
  }

  .StripeElement {
    width: 100%;
  }

  .chrome-picker {
    margin-left: -225px;
    margin-top: 65px
  }

  /* .carousel .slider-wrapper {
    width: 80% !important;
    margin: 0 !important;
  } */

  @font-face {
    font-family: 'Alef';
    font-display: swap;
    src: local('Alef'), url(../fonts/Alef-Regular.ttf) format('truetype');
    font-weight: normal;
  }

  @font-face {
    font-family: 'Alef';
    font-display: swap;
    src: local('Alef'), url(../fonts/Alef-Bold.ttf) format('truetype');
    font-weight: bold;
  }

  @font-face {
    font-family: 'Amiri';
    font-display: swap;
    src: local('Amiri'), url(../fonts/Amiri-Regular.ttf) format('truetype');
    font-weight: normal;
  }

  @font-face {
    font-family: 'Amiri';
    font-display: swap;
    src: local('Amiri'), url(../fonts/Amiri-Bold.ttf) format('truetype');
    font-weight: bold;
  }

  @font-face {
    font-family: 'Anton';
    font-display: swap;
    src: local('Anton'), url(../fonts/Anton-Regular.ttf) format('truetype');
    font-weight: normal;
  }

  @font-face {
    font-family: 'Arimo';
    font-display: swap;
    src: local('Arimo'), url(../fonts/Arimo-Regular.ttf) format('truetype');
    font-weight: normal;
  }

  @font-face {
    font-family: 'Arimo';
    font-display: swap;
    src: local('Arimo'), url(../fonts/Arimo-Bold.ttf) format('truetype');
    font-weight: bold;
  }

  @font-face {
    font-family: 'Cairo';
    font-display: swap;
    src: local('Cairo'), url(../fonts/Cairo-Regular.ttf) format('truetype');
    font-weight: normal;
  }

  @font-face {
    font-family: 'Cairo';
    font-display: swap;
    src: local('Cairo'), url(../fonts/Cairo-Bold.ttf) format('truetype');
    font-weight: bold;
  }

  @font-face {
    font-family: 'Comic Sans MS';
    font-display: swap;
    src: local('Comic Sans'), url(../fonts/ComicSansMS-Regular.ttf) format('truetype');
    font-weight: normal;
  }

  @font-face {
    font-family: 'Comic Sans MS';
    font-display: swap;
    src: local('Comic Sans'), url(../fonts/ComicSansMS-Bold.ttf) format('truetype');
    font-weight: 700;
  }

  @font-face {
    font-family: 'FatFrank';
    font-display: swap;
    src: local('FatFrank'), url(../fonts/FatFrank-Regular.ttf) format('truetype');
    font-weight: normal;
  }

  @font-face {
    font-family: 'Hind Siliguri';
    font-display: swap;
    src: local('Hind Siliguri'), url(../fonts/HindSiliguri-Regular.ttf) format('truetype');
    font-weight: normal;
  }

  @font-face {
    font-family: 'Hind Siliguri';
    font-display: swap;
    src: local('Hind Siliguri'), url(../fonts/HindSiliguri-Bold.ttf) format('truetype');
    font-weight: bold;
  }

  @font-face {
    font-family: 'Katibeh';
    font-display: swap;
    src: local('Katibeh'), url(../fonts/Katibeh-Regular.ttf) format('truetype');
    font-weight: normal;
  }

  @font-face {
    font-family: 'Lateef';
    font-display: swap;
    src: local('Lateef'), url(../fonts/LateefRegOT.ttf) format('truetype');
    font-weight: normal;
  }

  @font-face {
    font-family: 'Montserrat';
    font-display: swap;
    src: local('Montserrat'), url(../fonts/Montserrat-Light.ttf) format('truetype');
    font-weight: 300;
  }

  @font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-display: swap;
    src: local('Montserrat'), url(https://fonts.gstatic.com/s/montserrat/v15/JTUSjIg1_i6t8kCHKm459WlhyyTh89Y.woff2) format('woff2');
    font-weight: 'normal';
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  }

  @font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-display: swap;
    src: local('Montserrat'), url(https://fonts.gstatic.com/s/montserrat/v15/JTURjIg1_i6t8kCHKm45_dJE3gnD_vx3rCs.woff2) format('woff2');
    font-weight: bold;
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  }

  @font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-display: swap;
    src: local('Montserrat'), url(https://fonts.gstatic.com/s/montserrat/v15/JTURjIg1_i6t8kCHKm45_c5H3gnD_vx3rCs.woff2) format('woff2');
    font-weight: 800;
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  }

  @font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-display: swap;
    src: local('Montserrat'), url(https://fonts.gstatic.com/s/montserrat/v15/JTURjIg1_i6t8kCHKm45_epG3gnD_vx3rCs.woff2) format('woff2');
    font-weight: 900;
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  }

  @font-face {
    font-family: 'Mukta';
    font-display: swap;
    src: local('Mukta'), url(../fonts/Mukta-Regular.ttf) format('truetype');
    font-weight: normal;
  }

  @font-face {
    font-family: 'Mukta';
    font-display: swap;
    src: local('Mukta'), url(../fonts/Mukta-Bold.ttf) format('truetype');
    font-weight: bold;
  }

  @font-face {
    font-family: 'Noto Nastaliq Urdu';
    font-display: swap;
    src: local('Noto Nastaliq Urdu'), url(../fonts/NotoNastaliqUrdu-Regular.ttf) format('truetype');
    font-weight: normal;
  }

  @font-face {
    font-family: 'Noto Sans Arabic';
    font-display: swap;
    src: local('Noto Sans Arabic'), url(../fonts/NotoSansArabic-Regular.ttf) format('truetype');
    font-weight: normal;
  }

  @font-face {
    font-family: 'Noto Sans Arabic';
    font-display: swap;
    src: local('Noto Sans Arabic'), url(../fonts/NotoSansArabic-Bold.ttf) format('truetype');
    font-weight: bold;
  }

  @font-face {
    font-family: 'Noto Naskh Arabic';
    font-display: swap;
    src: local('Noto Naskh Arabic'), url(../fonts/NotoNaskhArabic-Regular.ttf) format('truetype');
    font-weight: normal;
  }

  @font-face {
    font-family: 'Noto Naskh Arabic';
    font-display: swap;
    src: local('Noto Naskh Arabic'), url(../fonts/NotoNaskhArabic-Bold.ttf) format('truetype');
    font-weight: bold;
  }

  @font-face {
    font-family: 'Old Standard TT';
    font-display: swap;
    src: local('Old Standard TT'), url(../fonts/OldStandardTT-Regular.ttf) format('truetype');
    font-weight: normal;
  }

  @font-face {
    font-family: 'Old Standard TT';
    font-display: swap;
    src: local('Old Standard TT'), url(../fonts/OldStandardTT-Bold.ttf) format('truetype');
    font-weight: bold;
  }

  @font-face {
    font-family: 'Oxygen';
    font-display: swap;
    src: local('Oxygen'), url(../fonts/Oxygen-Regular.ttf) format('truetype');
    font-weight: normal;
  }

  @font-face {
    font-family: 'Oxygen';
    font-display: swap;
    src: local('Oxygen'), url(../fonts/Oxygen-Bold.ttf) format('truetype');
    font-weight: bold;
  }

  @font-face {
    font-family: 'Philosopher';
    font-display: swap;
    src: local('Philosopher'), url(../fonts/Philosopher-Regular.ttf) format('truetype');
    font-weight: normal;
  }

  @font-face {
    font-family: 'Philosopher';
    font-display: swap;
    src: local('Philosopher'), url(../fonts/Philosopher-Bold.ttf) format('truetype');
    font-weight: bold;
  }

  /* latin-ext */
  @font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: normal;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/poppins/v15/pxiEyp8kv8JHgFVrJJnecnFHGPezSQ.woff2) format('woff2');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
  }

  @font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/poppins/v15/pxiByp8kv8JHgFVrLGT9Z1JlFd2JQEl8qw.woff2) format('woff2');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
  }

  @font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/poppins/v15/pxiByp8kv8JHgFVrLEj6Z1JlFd2JQEl8qw.woff2) format('woff2');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
  }

  @font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: bold;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/poppins/v15/pxiByp8kv8JHgFVrLCz7Z1JlFd2JQEl8qw.woff2) format('woff2');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
  }

  @font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 800;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/poppins/v15/pxiByp8kv8JHgFVrLDD4Z1JlFd2JQEl8qw.woff2) format('woff2');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
  }

  /* latin */
  @font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: normal;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/poppins/v15/pxiEyp8kv8JHgFVrJJfecnFHGPc.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  }

  @font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/poppins/v15/pxiByp8kv8JHgFVrLGT9Z1xlFd2JQEk.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  }

  @font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/poppins/v15/pxiByp8kv8JHgFVrLEj6Z1xlFd2JQEk.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  }

  @font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: bold;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/poppins/v15/pxiByp8kv8JHgFVrLCz7Z1xlFd2JQEk.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  }

  @font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 800;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/poppins/v15/pxiByp8kv8JHgFVrLDD4Z1xlFd2JQEk.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  }

  @font-face {
    font-family: 'Roboto';
    font-display: swap;
    src: local('Roboto'), url(../fonts/Roboto-Regular.ttf) format('truetype');
    font-weight: normal;
  }

  @font-face {
    font-family: 'Roboto Condensed';
    font-display: swap;
    src: local('Roboto Condensed'), url(../fonts/RobotoCondensed-Regular.ttf) format('truetype');
    font-weight: normal;
  }

  @font-face {
    font-family: 'Roboto Condensed';
    font-display: swap;
    src: local('Roboto Condensed'), url(../fonts/RobotoCondensed-Bold.ttf) format('truetype');
    font-weight: bold;
  }

  @font-face {
    font-family: 'Rubik';
    font-display: swap;
    src: local('Rubik'), url(../fonts/Rubik-Regular.ttf) format('truetype');
    font-weight: normal;
  }

  @font-face {
    font-family: 'Sarabun';
    font-display: swap;
    src: local('Sarabun'), url(../fonts/Sarabun-Regular.ttf) format('truetype');
    font-weight: normal;
  }

  @font-face {
    font-family: 'Sarabun';
    font-display: swap;
    src: local('Sarabun'), url(../fonts/Sarabun-Bold.ttf) format('truetype');
    font-weight: bold;
  }

  @font-face {
    font-family: 'Sathu';
    font-display: swap;
    src: local('Sathu'), url(../fonts/Sathu-Regular.ttf) format('truetype');
    font-weight: normal;
  }

  @font-face {
    font-family: 'Sazanami Mincho';
    font-display: swap;
    src: local('Sazanami Mincho'), url(../fonts/Sazanami-Hanazono-Mincho.ttf) format('truetype');
    font-weight: normal;
  }

  @font-face {
    font-family: 'Scheherazade';
    font-display: swap;
    src: local('Scheherazade'), url(../fonts/Scheherazade-Regular.ttf) format('truetype');
    font-weight: normal;
  }

  @font-face {
    font-family: 'Scheherazade';
    font-display: swap;
    src: local('Scheherazade'), url(../fonts/Scheherazade-Bold.ttf) format('truetype');
    font-weight: bold;
  }

  @font-face {
    font-family: 'Seravek';
    font-display: swap;
    src: local('Seravek'), url(../fonts/seravek.ttf) format('truetype');
    font-weight: normal;
  }

  @font-face {
    font-family: 'Tinos';
    font-display: swap;
    src: local('Tinos'), url(../fonts/Tinos-Regular.ttf) format('truetype');
    font-weight: normal;
  }

  @font-face {
    font-family: 'Tinos';
    font-display: swap;
    src: local('Tinos'), url(../fonts/Tinos-Bold.ttf) format('truetype');
    font-weight: bold;
  }

  @font-face {
    font-family: 'ZCOOL XiaoWei';
    font-display: swap;
    src: local('ZCOOL XiaoWei'), url(../fonts/ZCOOLXiaoWei-Regular.ttf) format('truetype');
    font-weight: normal;
  }
  
  @font-face {
    font-family: 'Chalkboard SE';
    font-display: swap;
    src: local('Chalkboard SE'), url(../fonts/ChalkboardSE-Regular.ttf) format('truetype');
    font-weight: normal;
  }

  @font-face {
    font-family: 'Chalkboard SE';
    font-display: swap;
    src: local('Chalkboard SE'), url(../fonts/ChalkboardSE-Bold.ttf) format('truetype');
    font-weight: 700;
  }

  .MuiDataGrid-viewport {
    max-width: none !important;
  }
`;
